import { render, staticRenderFns } from "./Roland.vue?vue&type=template&id=58bc1d11&scoped=true"
import script from "./Roland.vue?vue&type=script&lang=js"
export * from "./Roland.vue?vue&type=script&lang=js"
import style0 from "./Roland.vue?vue&type=style&index=0&id=58bc1d11&prod&lang=stylus&scoped=true"
import style1 from "./Roland.vue?vue&type=style&index=1&id=58bc1d11&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58bc1d11",
  null
  
)

export default component.exports