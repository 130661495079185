<template>
  <div class="formBody">
    <v-row align="center" style="width: 100vw">
      <v-row justify="space-around" style="margin: 0 0 0 0">
        <div>
          <v-dialog
              v-model="dialog"
              width="500"
          >
            <v-card>
              <v-card-title
                  class="headline primary"
                  primary-title
              >
                {{ this.$t('dialog.dataFound.headline') }}
              </v-card-title>

              <v-card-text class="mt-3">
                {{ this.$t('dialog.dataFound.body') }}
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="error"
                    text
                    @click="clearRecoverData()"
                >
                  {{ this.$t('button.delete') }}
                </v-btn>
                <v-btn
                    color="primary"
                    @click="recoverData()"
                >
                  {{ this.$t('button.restore') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <h1>Roland</h1>
          <v-progress-linear indeterminate color="primary" v-if="isLoading"></v-progress-linear>

          <v-form
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
              class="form"
          >
            <v-container style="min-width: 25vw; max-width: 80vw" v-on:focusout="save()"
                         v-on:input="save()">
              <v-text-field
                  v-model="policyNumber"
                  :label="inputLabels.policyNumber"
                  :rules="policyNumberRules"
                  autocomplete="off"
                  type="number"
              ></v-text-field>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="name"
                      :label="inputLabels.name"
                      :rules="nameRules"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="lastName"
                      :label="inputLabels.lastName"
                      :rules="nameRules"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                  v-model="company"
                  :label="inputLabels.company"
                  :rules="companyRules"
                  required
              ></v-text-field>

              <v-combobox
                  v-model="fieldOfLaw"
                  :items="fieldOfLaws"
                  :label="inputLabels.fieldOfLaw"
                  :placeholder="inputLabels['fieldOfLawSearch']"
                  :rules="fieldOfLawRules"
                  color="primary"
                  hide-no-data
                  hide-selected
                  required
              ></v-combobox>

              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="mobile"
                      :label="inputLabels.mobile"
                      :rules="mobileRules"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="phone"
                      :label="inputLabels.phone"
                      :rules="mobileRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                  v-model="email"
                  :label="inputLabels.email"
                  :rules="emailRules"
              ></v-text-field>
              <v-row>
                <v-col
                    cols="12"
                    md="8"
                >
                  <v-text-field
                      v-model="street"
                      :label="inputLabels.street"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="streetNumber"
                      :label="inputLabels.streetNumber"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    md="4"
                >
                  <v-text-field
                      v-model="plz"
                      :label="inputLabels.plz"
                      :rules="plzRules"
                      required
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="8"
                >
                  <v-text-field
                      v-model="place"
                      :label="inputLabels.place"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    md="4"
                >
                  <v-checkbox
                      v-model="recall"
                      :label="inputLabels.recall"
                      v-on:change="save()"
                  ></v-checkbox>
                </v-col>
                <v-col
                    cols="12"
                    md="8"
                >
                  <v-menu
                      v-if="recall"
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="date"
                          v-on="on"
                          :label="inputLabels['recallDate']"
                          prepend-icon="mdi-calendar"
                          readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" color="primary" show-current
                                   @input="saveDate()"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                >
                  <v-menu
                      v-model="consultationDayMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-on="on"
                          :label="inputLabels['consultationDate']"
                          :value="formatDate(consultationDay)"
                          prepend-icon="mdi-calendar"
                          readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="consultationDay" color="primary" show-current
                                   @input="saveDate()"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                    cols="12"
                    md="12">
                  <v-textarea
                      v-model="issue"
                      :counter="500"
                      :label="inputLabels.issue"
                      auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>

            <v-row
                class="mb-10"
                justify="end"
                md="4"
            >
              <v-btn
                  class="mr-4"
                  color="error"
                  @click="$router.back()"
              >
                {{ this.$t('button.cancel') }}
              </v-btn>

              <v-btn
                  :disabled="blockButtonWhileRequestAndForm()"
                  class="mr-4"
                  color="success"
                  @click="validate"
              >
                {{ this.$t('button.sent') }}
              </v-btn>
            </v-row>
          </v-form>
        </div>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import i18n from "../../plugins/i18n";
import {getRolandURL, requestMode} from "@/configBuilder";
import {sessionHandler} from "@/request/sessionHandler";
import {showAlert} from "@/utils/alertHandler";
import dateFormatter from "../../utils/dateFormatter";

export default {
  name: "Roland",
  data: () => ({
    valid: true,
    isLoading: false,
    name: '',
    lastName: '',
    company: '',
    fieldOfLaw: '',
    policyNumber: {},
    mobile: '',
    phone: '',
    email: '',
    street: '',
    streetNumber: '',
    plz: '',
    place: '',
    issue: '',
    consultationDay: new Date().toISOString().substr(0, 10),
    recall: false,
    dialog: false,
    date: new Date().toISOString().substr(0, 10),
    fieldOfLaws: [
      "Verkehrsunfallrecht",
      "KFZ-Vertragsrecht",
      "KFZ-Steuerrecht",
      "KfZ-Verwaltungsrecht",
      "Insolvenzrecht",
      "Strafrecht Verkehrsbereich",
      "Ordnungswidrigkeitenrecht Verkehrsbereich",
      "Schadenersatzrecht",
      "Arbeitsrecht",
      "Allgemeines Vertrags- und Sachenrecht",
      "Steuerrecht",
      "Sozialrecht",
      "Diziplinarrecht",
      "Strafrecht",
      "Ordnungswidrigkeitenrecht",
      "Familien- und Erbrecht",
      "Mietrecht",
      "Immobilienrecht",
    ],
    dateMenu: false,
    consultationDayMenu: false,
    lazy: false,
  }),

  methods: {
    // disable sent button so prevent multiple entries
    blockButtonWhileRequestAndForm(){
      if (this.valid === false) {return true}
      return this.isLoading === true;
    },

    // Form Data Request
    async sendData() {
      // set Loading true
      this.isLoading = true;

      // init Request Data
      let callbackDate;
      this.recall ? callbackDate = this.date : callbackDate = '';
      const formData = {
        "callbackDate": callbackDate,
        "comment": this.issue,
        "company": this.company,
        "consultationDate": this.consultationDay,
        "contractNumber": this.policyNumber,
        "customerCity": this.place,
        "customerMailAddress": this.email,
        "customerMobileNumber": this.mobile,
        "customerName": this.name,
        "customerLastname": this.lastName,
        "customerPhoneNumber": this.phone,
        "customerStreet": this.street,
        "customerStreetnumber": this.streetNumber,
        "customerZipCode": this.plz,
        "fieldOfLaw": this.fieldOfLaw,
      };

      //  if Session isValid
      await sessionHandler();

      // send add to Database request
      await this.$http
          .post(
              getRolandURL(false),
              formData
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.clearRecoverData();
              showAlert(i18n.t('success.form'), "success");
              this.$router.push("/")
            } else {
              showAlert(i18n.t('error.api.undefined') + "Roland-sD1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              } else {
                showAlert(i18n.t('error.api.undefined') + "Roland-sD2", "error");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "AdvocardPrivate-sD2", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },

    // send button pressed
    validate() {
      if (this.$refs.form.validate()) {
        this.sendData();
      }
    },

    // Save to localStorage
    save() {
      let store = {
        "policyNumber": this.policyNumber,
        "name": this.name,
        "lastName": this.lastName,
        "company": this.company,
        "fieldOfLaw": this.fieldOfLaw,
        "mobile": this.mobile,
        "phone": this.phone,
        "email": this.email,
        "street": this.street,
        "streetNumber": this.streetNumber,
        "plz": this.plz,
        "place": this.place,
        "recall": this.recall,
        "date": this.date,
        "issue": this.issue,
        "consultationDay": this.consultationDay
      }
      localStorage.setItem('roland', JSON.stringify(store));

    },
    saveDate() {
      this.save();
      this.dateMenu = false
    },

    // Clear localStorage
    clearRecoverData() {
      localStorage.setItem('roland', "")
      this.dialog = false;
    },

    // Set localStorage to Formfields
    recoverData() {
      let recoverableData = localStorage.getItem('roland');
      recoverableData = JSON.parse(recoverableData);
      this.policyNumber = recoverableData.policyNumber;
      this.name = recoverableData.name;
      this.lastName = recoverableData.lastName;
      this.company = recoverableData.company;
      this.fieldOfLaw = recoverableData.fieldOfLaw;
      this.mobile = recoverableData.mobile;
      this.phone = recoverableData.customerPhoneNumber;
      this.email = recoverableData.email;
      this.street = recoverableData.street;
      this.streetNumber = recoverableData.streetNumber;
      this.plz = recoverableData.plz;
      this.place = recoverableData.place;
      this.recall = recoverableData.recall;
      this.date = recoverableData.date;
      this.issue = recoverableData.issue;
      this.consultationDay = recoverableData.consultationDay;
      this.dialog = false
    },

    //  if there is data to recover
    checkRecoverData() {
      let recoverableData = localStorage.getItem('roland');
      if (recoverableData) {
        this.dialog = true;
      }
    },

    // format Date
    formatDate(unformedDate) {
      return dateFormatter(unformedDate);
    }
  },
  computed: {
    errorMessage() {
      return JSON.parse(JSON.stringify(this.$t('error.validation')));
    },
    inputLabels() {
      return JSON.parse(JSON.stringify(this.$t('insurance.Roland.inputField')));
    },
    policyNumberRules() {
      return [
        v => /^\d+$/.test(v) || this.errorMessage["onlyNumbers"],
        v => !!v || this.errorMessage.required,
      ]
    },
    nameRules() {
      return [
        v => !!v || this.errorMessage.required,
      ]
    },
    companyRules() {
      return [
        v => !!v || this.errorMessage.required,
      ]
    },
    mobileRules() {
      return [
        v => v.length === 0 ? true : /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(v) || this.errorMessage["onlyNumbers"],
      ];
    },
    emailRules() {
      return [
        v => v.length === 0 ? true : /.+@.+/.test(v) || this.errorMessage.valid
      ]
    },
    plzRules() {
      return [
        v => /^[0-9]{5}$/g.test(v) || this.errorMessage.valid
      ]
    },
    fieldOfLawRules() {
      return [
        v => !!v || this.errorMessage.required,
      ]
    }
  },
  mounted() {
    this.checkRecoverData();
  }
}
</script>

<style lang="stylus" scoped>
.formBody
  margin-top 3vh

.form
  margin-top 1vh

</style>

<!-- Hide Number Button -->
<style>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
